<template>
  <div class="initiative" :class="{ dragging: dragging !== null }">
    <h3>Initiative</h3>
    <div v-if="dungeonMaster" class="container center">
      <Icon name="estia:d20" @click="rollInitiative" />
      <Icon name="game-icons:card-exchange" @click="consolidateInitiative" />
    </div>
    <div class="dragOverlay container vertical stretch">
      <div
        v-for="(_, index) in Array(26)"
        :key="index"
        class="row"
        :class="{ highlight: index % 5 === 0 }"
        @dragover.prevent=""
        @drop="drop($event, 25 - index)"
      >
        {{ 25 - index }}
      </div>
      <div class="row" @dragover.prevent="" @drop="drop($event, -1)">No Initiative</div>
    </div>

    <TransitionGroup name="init-list" tag="div" class="players">
      <div
        v-for="player in players"
        :key="player.id"
        class="player"
        :class="{ enemy: !player.friendly, 'no-initiative': player.initiative === -1 }"
        draggable="true"
        @dragstart="dragStart($event, player.id)"
      >
        <div class="background" :style="{ 'background-image': player.sprite }"></div>
        <div class="init button" :title="`${player.initMod || '-'}`">
          {{ player.initiative }}
        </div>
        <div class="name">{{ player.name }}{{ number(player) }}</div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import { contains, resolveInitiative, type CharData } from './tabletopUtil'
import type { MultiDiceResult, SingleDiceResult } from '~/assets/dice/diceEval'

const props = defineProps<{
  dungeonMaster: boolean
}>()

const { table, sendCommand } = useTabletop()

const dragging = ref<string | null>(null)

const players = computed(() => {
  const chars = table.value.characters
  return Object.values(chars)
    .sort((a, b) => {
      let cmp = (a.initiative ?? 0) - (b.initiative ?? 0)
      if (cmp !== 0) return cmp
      cmp = a.name.localeCompare(b.name)
      if (cmp !== 0) return cmp
      return (a.number ?? 0) - (b.number ?? 0)
    })
    .filter((char) => {
      if (props.dungeonMaster || char.friendly) return true
      const inFog = Object.values(table.value.map.fog).findIndex((fog) => contains(fog.rect, char.position)) !== -1
      // no point in removing invisible characters.
      // const invis = char.conditions.findIndex(cond => cond === 'invisible') !== -1
      return !inFog
    })
    .filter((char, i, arr) => {
      // simplify the list so successive characters with the same name and initiative are not shown twice
      const prev = arr[i - 1] // oob access simply returns undefined
      return !(prev && char.name === prev.name && char.initiative === prev.initiative)
    })
})

const setInitiative = (id: string, initiative: number) => sendCommand('SET_INITIATIVE', { id, initiative })

const dragStart = (_ev: Event, player: string) => {
  if (!props.dungeonMaster) return
  dragging.value = player
}

const drop = (_ev: Event, init: number) => {
  if (dragging.value === null) return
  setInitiative(dragging.value, init)
  dragging.value = null
}

const number = (char: CharData) => {
  const chars = Object.values(table.value.characters)
  const sameName = chars.filter((c) => c.name === char.name)

  if (sameName.length < 2) return ''
  const sameInit = sameName.filter((c) => c.initiative === char.initiative)
  return ` ${sameInit.map((c) => c.number).join(', ')}`
}

const consolidateInitiative = () => {
  const chars = Object.values(table.value.characters)
  chars.forEach((char) => {
    const sameName = chars.filter((c) => c.name === char.name)
    if (sameName.length < 2) return
    // find the lowest-numbered creature with the same name
    const lowest = sameName.reduce((a, b) => ((a.number ?? 0) < (b.number ?? 0) ? a : b))
    if (char.number !== lowest.number && char.initiative !== lowest.initiative) {
      setInitiative(char.id, lowest.initiative || 0)
    }
  })
}

const store = useStore()
const broadcasting = computed(() => store.playerData.broadcastDice)
const broadcastInitiativeRoll = async (char: CharData, result: SingleDiceResult) => {
  if (!broadcasting.value) return

  const body: MultiDiceResult = {
    results: [result],
    player: char.name,
    id: Math.floor(10000000 * Math.random()),
    hidden: false,
    label: 'Initiative',
  }

  await fetch('https://estia-server-m6x2fqeyga-ew.a.run.app/dice', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

const rollInitiative = (ev: MouseEvent) => {
  const isSurprised = ev.shiftKey
  Object.values(table.value.characters)
    .filter((char) => char.friendly && char.initMod !== null)
    .forEach((char) => {
      const { initiative, result } = resolveInitiative(char, isSurprised)
      if (result) {
        broadcastInitiativeRoll(char, result)
      }
      setInitiative(char.id, initiative)
    })
}
</script>

<style lang="postcss" scoped>
.init-list {
  position: relative;
}

.init-list-move {
  transition: transform 1s;
}

.init-list-enter,
.init-list-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.init-list-leave-active {
  position: absolute !important;
}

.initiative {
  width: 100%;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    line-height: 1.1;
    width: 40px;
    flex: 0 0 40px;
    height: 40px;
    color: var(--text-highlight);
    font-family: var(--font-stack-title);
    font-variation-settings: 'wdth' 5;
    border: 2px solid currentColor;
    border-radius: 50%;
    margin-right: 10px;
    padding-bottom: 2px;
    z-index: 10;
    background: var(--background);

    > i {
      font-size: 20px;
      margin-bottom: -4px;
    }
  }

  .dragOverlay {
    display: none;

    .row {
      text-align: center;

      &.highlight {
        font-weight: 800;
        background: var(--background-light);
      }
    }
  }

  .players {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    transition: order 1s;
  }

  &.dragging {
    .dragOverlay {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .players,
    .edit-modal {
      display: none;
    }
  }

  .player {
    padding: 5px;
    margin: 5px;
    border: 2px solid #3c0;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: transform 1s, opacity 0.75s;
    position: relative;

    .background {
      position: absolute;

      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      opacity: 0.3;
    }

    &.no-initiative {
      border: 2px solid var(--text);
      padding: 2px 1em;
      margin: 2px 5px;

      .background {
        display: none;
      }

      .name {
        text-align: center;
      }

      .init {
        display: none;
      }

      &.enemy {
        border-color: var(--text);
      }

      &:last-of-type {
        margin-top: 1em;
      }
    }

    &.active {
      box-shadow: 0 0 10px inset gold;
    }

    &.editing {
      box-shadow: 0 0 10px inset cyan;
    }

    &.enemy {
      border-color: #a30;
    }

    &.new {
      border-color: transparent;
    }

    .name {
      font-weight: 500;
      z-index: 10;
      flex: 1;
      text-shadow: 0 0 4px var(--background), 0 0 2px var(--background);
    }
  }
}
</style>
